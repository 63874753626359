<template>
  <div id="app">
    <div class="notif-note" v-show="showNote">
      <span>
        Ce site n'est pas officiel et n'est pas géré par l'équipe RTS eSport.
        <span class="link" @click="setConsent">[ OK ]</span>
      </span>
    </div>

    <programme-img v-if="showProgr" @close="showProgr = false" />

    <header>
      <div class="nav-right">
        <router-link to="/">
          <img class="logo" title="RTS eSport" src="./assets/logo-tr.png">
        </router-link>
      </div>
      <div class="nav-left">
        <span class="no-mob">
          <router-link to="/">Live</router-link>
          &bull;
        </span>
        <router-link to="/vod">VOD</router-link>
        &bull;
        <span class="link" @click="showProgr = true">Planning</span>
        &nbsp;
        <span class="icons">
          &bull;
          <a target="_blank" href="https://discord.gg/76VfjDZ">
            <img class="icon" title="Discord" src="./assets/discord.png"></a>
            &nbsp;
          <a target="_blank" href="https://twitter.com/RtsEsport">
            <img class="icon" title="Twitter" src="./assets/twitter.png"></a>
        </span>
      </div>
    </header>

    <router-view />
  </div>
</template>

<script>
import ProgrammeImg from '@/components/ProgrammeImg.vue'

export default {
  components: {
    ProgrammeImg
  },
  data () {
    return {
      showNote: false,
      showProgr: false
    }
  },
  mounted () {
    const o = localStorage.getItem('notOfficial')
    if (o !== 'ok') {
      this.showNote = true
    }
  },
  methods: {
    setConsent: function () {
      this.showNote = false
      localStorage.setItem('notOfficial', 'ok')
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #141428;
  color: #ffffff;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 0;

  --app-header-height: 48px; // best when maximized + favbar shown
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.notif-note {
  align-items: center;
  background-color: #af001e;
  display: flex;
  font-size: 12px;
  height: var(--app-header-height);
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

header {
  align-items: center;
  background-color: #141428;
  background-image: url('./assets/header.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .logo {
    height: var(--app-header-height);
    margin-right: 15px;
    vertical-align: -24px;
  }

  .nav-left {
    margin-right: 15px;
    text-align: right;

    .icons {
      display: inline-block;

      .icon {
        height: 1em;
        vertical-align: middle;
      }
    }

  }
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 360px) {
  .no-mob {
    display: none;
  }
}

@media (orientation: portrait), only screen and (max-width: 680px) {
  header {
    background-position: center bottom;
    font-size: 14px;

    .logo {
      height: 40px;
    }
  }
}
</style>
