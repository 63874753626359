<template>
  <div class="home">
    <div v-if="offline" class="offline-note">
      <a href="https://www.twitch.tv/rtsesport_tv">[ HORS LIGNE ]</a>
    </div>
    <video v-if="offline" @click="togglePlayPauseTrailer" src="/trailer.mp4" autoplay muted loop></video>
    <div id="twitch-player"></div>
  </div>
</template>

<script>
/* globals Twitch */
export default {
  name: 'home',
  data () {
    return {
      player: null,
      offline: false
    }
  },
  mounted () {
    this.player = new Twitch.Embed('twitch-player', {
      allowfullscreen: true,
      autoplay: true,
      channel: 'rtsesport_tv',
      layout: 'video-with-chat',
      width: '100%'
    })

    this.player.addEventListener(Twitch.Player.PLAYING, _ => {
      this.offline = false
    })
    this.player.addEventListener(Twitch.Player.ONLINE, _ => {
      this.offline = false
      this.player.play()
    })

    this.player.addEventListener(Twitch.Player.ENDED, _ => {
      this.offline = true
    })
    this.player.addEventListener(Twitch.Player.OFFLINE, _ => {
      this.offline = true
    })
  },
  methods: {
    togglePlayPauseTrailer: (ev) => {
      const vid = ev.target
      if (vid.paused) vid.play()
      else vid.pause()
    }
  }
}
</script>

<style lang="scss">
.home {
  .offline-note {
    color: white;
    position: fixed;
    top: 15px;
    left: 75px;
    z-index: 25;
  }

  video {
    background: #141428;
    height: calc(100vh - var(--app-header-height));
    position: fixed;
    top: var(--app-header-height);
    width: calc(100vw - 340px);
    z-index: 20;
  }

  iframe {
    height: calc(100vh - var(--app-header-height));
    margin-bottom: -10px; // wtf?
    z-index: 10;
  }
}

@media (orientation: portrait), only screen and (max-width: 680px) {
  .home {
    .offline-note,
    video {
      display: none;
    }

    iframe {
      height: calc(100vh - 40px);
    }
  }
}
</style>
