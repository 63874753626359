<template>
  <transition appear name="modal">
    <div class="modal-mask">
      <button class="modal-close" @click="closeProgr">
        &times; Fermer
      </button>
      <div class="modal-wrapper">
        <div class="modal-container">
          <img src="https://s.kdy.ch/rtse/tt.png" v-click-outside="closeProgr">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    closeProgr: function () {
      this.$emit('close')
    }
  },
  name: 'ProgrammeImg'
}
</script>

<style lang="scss">
.modal-mask {
  background-color: rgba(0, 0, 0, .9);
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 9998;

  .modal-wrapper {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
  }

  .modal-close {
    background: #000000;
    border: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: .7em;
    position: fixed;
    right: 15px;
    top: 20px;
  }
}

.modal-container {
  border-radius: 2px;
  height: calc(100vh - 140px);
  margin: 20px auto;
  min-width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px;
  text-align: center;
  transition: all .3s ease;
  width: calc(100vw - 50px);

  img {
    cursor: initial;
    height: calc(100vh - 140px);
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: translateY(-110vh);
}
</style>
